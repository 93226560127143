import { Card, CardBody } from '@chakra-ui/react'
import { Property, PropertyLabel, PropertyList, PropertyValue } from '@saas-ui/react'

export default function DetailsList({
  data,
}: {
  data: [string | null, JSX.Element][] | undefined
}) {
  return (
    <Card>
      <CardBody>
        <PropertyList>
          {data?.map(([key, value], index) => (
            <Property key={`${key}-${index}`}>
              <PropertyLabel width="200px">{key}</PropertyLabel>
              <PropertyValue>{value}</PropertyValue>
            </Property>
          ))}
        </PropertyList>
      </CardBody>
    </Card>
  )
}
