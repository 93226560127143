const localStorageEffect =
  (key: string) =>
  ({ setSelf, onSet }: { setSelf: any; onSet: any }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue !== null && savedValue !== undefined) {
      setSelf(JSON.parse(savedValue))
    }

    onSet((newValue: any, _: any, isReset: any) => {
      if (isReset || newValue === undefined) {
        localStorage.removeItem(key)
      } else {
        localStorage.setItem(key, JSON.stringify(newValue))
      }
    })
  }
export default localStorageEffect
