import { Page, PageBody, PageHeader } from '@saas-ui-pro/react'
import { AppShell } from '@saas-ui/react'
import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import PageBanner, { EnvType } from '~/layout/PageBanner'
import SidebarLayout, { LinkItemProps } from '~/layout/SidebarLayout'
import { supabase } from '~/utils/supabaseClient'

type LayoutProps = {
  sidebarLinks: Array<LinkItemProps>
}

const Layout = ({ sidebarLinks }: LayoutProps) => {
  let env: EnvType = 'prod'
  switch (window.location.hostname) {
    case 'demo.dumbo.works':
      env = 'demo'
      break
    case 'staging.dumbo.works':
      env = 'staging'
      break
    case 'localhost':
      env = 'dev'
      break
  }
  const railwayPrRegex = /trunk-watchtower-trunk-watchtower-pr-.*\.up\.railway\.app/
  if (railwayPrRegex.test(window.location.hostname)) {
    env = 'staging'
  }
  return (
    <AppShell
      height="$100vh"
      sidebar={<SidebarLayout links={sidebarLinks} logout={() => supabase.auth.signOut()} />}
      navbar={<PageBanner env={env} />}
    >
      <Suspense
        fallback={
          <Page isLoading>
            <PageHeader title="Loading page" />
            <PageBody />
          </Page>
        }
      >
        <Outlet />
      </Suspense>
    </AppShell>
  )
}

export default Layout
