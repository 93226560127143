import { QueryClient } from '@tanstack/react-query'
import { TFunction } from 'i18next'
import JobState, { mapJobStateTitle } from '~/types/JobState.types'
import { Database } from '~/types/database.types'
import convertToCSV from '~/utils/convertToCSV'
import { supabase } from '~/utils/supabaseClient'
import { formatEventMetadata } from './utils'

export type JobDetails = {
  job_card_operation_name: string | null
  job_card_current_status: JobState
  work_order_id: string
  zone_name: string | null
  station_name: string | null
  job_card_latest_headcount: number | null
  job_card_expected_headcount: number | null
  job_card_takt_time: string | null
  job_card_cycle_time: string | null
  job_card_downtime: string | null
  job_card_overrun_time: string | null
  job_card_started_at: string | null
  job_card_updated_at: string | null
  operation_versions: {
    operation_name: string
  }
} & {
  work_orders: {
    work_order_id: string
    work_order_name: string
    bill_of_operations_versions: {
      bill_of_operations_id: string
      bill_of_operations_name: string
      bill_of_operations_version: number
    }
    work_order_taggings: {
      work_order_tag: string
      work_order_tag_category: string
    }[]
  }
} & {
  job_workers: {
    workers: {
      worker_name: string
      worker_first_name: string
      worker_last_name: string
    }
  }[]
}

export const jobDetailQuery = (id: string) => ({
  queryKey: ['job', 'detail', id],
  queryFn: async () => {
    const job = await supabase
      .from('job_cards')
      .select(
        `
    job_card_operation_name,
    job_card_current_status,
    work_order_id,
    zone_name,
    station_name,
    job_card_latest_headcount,
    job_card_expected_headcount,
    job_card_takt_time,
    job_card_cycle_time,
    job_card_downtime,
    job_card_overrun_time,
    job_card_started_at,
    operation_versions (
      operation_name
    ),
    job_workers(
      workers (
        worker_name,
        worker_first_name,
        worker_last_name
      )
    ),
    work_orders (
      work_order_id,
      work_order_name,
      bill_of_operations_versions (
        bill_of_operations_id,
        bill_of_operations_name,
        bill_of_operations_version
      ),
      work_order_taggings (
        work_order_tag,
        work_order_tag_category
      )
    )
    `,
      )
      .eq('job_card_id', id)
      .maybeSingle()

    if (!job) {
      throw new Response('', {
        status: 404,
        statusText: 'Not Found',
      })
    }
    return job.data as JobDetails
  },
})

export type JobEvent = {
  job_card_id: string
  job_event_type: JobState
  job_event_metadata: { [key: string]: any }
  job_event_id: string
  job_event_user: string
  job_event_occurrence: string
  job_cards: {
    work_order_id: string
  }
}

export const jobEventsQuery = (options: {
  jobCardId: string
  pageIndex?: number
  pageSize?: number
}) => ({
  queryKey: ['job', 'events', options.jobCardId],
  queryFn: async () => {
    const request = supabase
      .from('job_events')
      .select('*, job_cards(work_order_id)', { count: 'exact' })
      .eq('job_card_id', options.jobCardId)
      .order('job_event_occurrence', {
        ascending: false,
      })
    if (options.pageIndex && options.pageSize) {
      request.range(
        options.pageIndex * options.pageSize,
        (options.pageIndex + 1) * options.pageSize - 1,
      )
    }
    const { data: jobEvents, count } = await request
    if (!jobEvents) {
      throw new Response('', {
        status: 404,
        statusText: 'Not Found',
      })
    }
    // Get worker names for all job_workers_changed events
    const eventsWithWorkerNames = await Promise.all(
      jobEvents.map(async (event) => {
        if (
          event.job_event_type === 'job_workers_changed' ||
          event.job_event_type === 'started' ||
          event.job_event_type === 'restarted'
        ) {
          const metadata = event?.job_event_metadata as { [key: string]: any }
          const { data } = await supabase
            .from('workers')
            .select('worker_name')
            .in('worker_id', metadata.job_workers ?? [])
          return {
            ...event,
            job_event_metadata: {
              ...metadata,
              job_worker_names: data?.map((worker) => worker.worker_name) ?? [],
            },
          }
        }
        return event
      }),
    )

    return {
      rows: eventsWithWorkerNames as JobEvent[] | null,
      pageCount: count && options.pageSize ? Math.ceil(count / options.pageSize) : null,
    }
  },
})

export const jobEventsCSVQuery = async (t: TFunction, jobCardId: string) => {
  const { data, error } = await supabase
    .from('job_events')
    .select('*, job_cards(work_order_id)', { count: 'exact' })
    .eq('job_card_id', jobCardId)
    .order('job_event_occurrence', {
      ascending: false,
    })

  const csv = convertToCSV(
    (data as JobEvent[] | null)?.map((row, index) => {
      return {
        event_type: mapJobStateTitle(t, row.job_event_type),
        event_occurrence: row.job_event_occurrence,
        event_mata_data: `"${formatEventMetadata(t, row, index, data as JobEvent[])}"`,
      }
    }),
  )
  return {
    csv,
    error,
  }
}

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: any) => {
    const query = jobDetailQuery(params.jobCardId)
    return queryClient.getQueryData(query as any) ?? (await queryClient.fetchQuery(query))
  }

type Operation = Database['public']['Tables']['operation_versions']['Row']
export type JobResponseSuccess = Database['public']['Tables']['job_cards']['Row'] & {
  operation_versions: Operation
}
