/**
 *
 * @param array
 * @param fromIndex
 * @param toIndex
 */
export const moveArrayItem = <T>(array: T[], fromIndex: number, toIndex: number): T[] => {
  const arrayCopy = [...array] // a shallow copy of the array
  if (fromIndex === toIndex) {
    return arrayCopy
  }
  const [item] = arrayCopy.splice(fromIndex, 1) // remove the item from the array
  arrayCopy.splice(toIndex, 0, item) // insert the item at the new index
  return arrayCopy
}

/**
 * Removes an element from an array at the specified index.
 *
 * @param array - The array from which to remove an element.
 * @param index - The index of the element to remove.
 * @returns A new array with the element removed.
 */
export const removeElementAtIndex = <T>(array: T[], index: number): T[] => {
  if (index < 0 || index >= array.length) {
    throw new Error('Index out of bounds')
  }
  const result = array.slice() // Make a copy to avoid mutating the original array
  result.splice(index, 1) // Remove the element at the specified index
  return result
}
