import { Button, useToast } from '@chakra-ui/react'
import { Page, PageBody, PageHeader } from '@saas-ui-pro/react'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { newSandboxPartners } from './api'
// import { newDemoWorkOrder, runScenario1 } from './api'

export default function TestingPage() {
  const { t } = useTranslation(['testing'])
  const toast = useToast()

  const mutationSetupSandboxPartners = useMutation({
    mutationFn: newSandboxPartners,
    onSuccess: () => {
      toast({
        title: t('success', { ns: 'translation' }),
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    },
    onError: (error: any) => {
      toast({
        title: t('error', { ns: 'translation' }),
        description: error?.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    },
  })

  return (
    <Page>
      <PageHeader title={t('title')} />
      <PageBody>
        <Button
          colorScheme="teal"
          isLoading={mutationSetupSandboxPartners.isPending}
          onClick={() => mutationSetupSandboxPartners.mutate()}
        >
          {t('setup_sandbox_partners')}
        </Button>
      </PageBody>
    </Page>
  )
}
