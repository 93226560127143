import { Beacon } from '@saas-ui-pro/react'
import { REALTIME_SUBSCRIBE_STATES } from '~/utils/subscriptionHelpers'

type SubIndicatorProps = {
  subscriptionState: REALTIME_SUBSCRIBE_STATES | null
}

const SubscriptionIndicator = ({ subscriptionState }: SubIndicatorProps) => (
  <Beacon colorScheme={subscriptionState === 'SUBSCRIBED' ? 'green' : 'red'} />
)

export default SubscriptionIndicator
