import { selector } from 'recoil'
import sessionStorage from './sessionStorage'

export const isTrunkUserCheck = selector({
  key: 'IsTrunkUser',
  get: ({ get }) => {
    const email = get(sessionStorage)?.user?.email
    return email ? email.endsWith('@trunk.works') || email.endsWith('@artic.works') : false
  },
})
