import { PaginationState, SortingState } from '@tanstack/react-table'

export default class SupabaseQueryHelper {
  static addPagination = (query: any, pagination: PaginationState) =>
    query.range(
      pagination.pageIndex * pagination.pageSize,
      (pagination.pageIndex + 1) * pagination.pageSize - 1,
    )

  static addSorting = (query: any, sorting: SortingState) =>
    sorting.forEach((sort) => {
      const columns = sort.id.split('.')
      const desc = sort.desc
      const column = columns[columns.length - 1]
      if (columns.length > 1) {
        query.order(column, {
          ascending: !desc,
          foreignTable: columns.slice(0, columns.length - 1).join('.'),
          nullsFirst: false,
        })
      } else {
        query.order(columns[0] as any, {
          ascending: !desc,
          nullsFirst: false,
        })
      }
    })

  static getPageCount = (count: number | null, pagination?: PaginationState) =>
    count && pagination ? Math.ceil(count / pagination.pageSize) : 0
}
