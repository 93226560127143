import { selector } from 'recoil'
import { supabase } from '../utils/supabaseClient'

export const partnersQuery = selector({
  key: 'PartnersList',
  get: async () => {
    const response = await supabase.from('partners').select('*')
    return response.data
  },
})
