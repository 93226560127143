export const pageTheme = {
  defaultProps: {
    // this variant adds spacing next to the title for the sidebar toggle button on mobile screens.
    variant: 'default-sidebar',
  },
  baseStyle: {
    container: {
      background: 'gray.50',
    },
  },
}
