import { ChevronRightIcon } from '@chakra-ui/icons'
import { Breadcrumb, BreadcrumbItem } from '@chakra-ui/react'
import { useMatches } from 'react-router-dom'

export default function Breadcrumbs() {
  const matches = useMatches()
  const crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => Boolean((match.handle as any | undefined)?.crumb))
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match) => (match.handle as any).crumb(match.data))
  return (
    <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
      {crumbs.map((crumb, index) => {
        return (
          <BreadcrumbItem key={index} isCurrentPage={index === crumbs.length - 1}>
            {crumb}
          </BreadcrumbItem>
        )
      })}
    </Breadcrumb>
  )
}
