import { Button, IconButton } from '@chakra-ui/react'
import { SidebarSection } from '@saas-ui/react'
import { useTranslation } from 'react-i18next'
import { CiLogout } from 'react-icons/ci'
import { GrTest } from 'react-icons/gr'
import { RiPresentationFill } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { isTrunkUserCheck } from '~/atoms/isTrunkUserCheck'
import LanguagePicker from '~/components/LanguagePicker'

export const SidebarFooter = ({ isOpen, logout }: { isOpen: boolean; logout: () => void }) => {
  const isTrunkUser = useRecoilValue(isTrunkUserCheck)
  const { t } = useTranslation('translation')
  const navigate = useNavigate()

  if (!isOpen) {
    return (
      <SidebarSection>
        <IconButton onClick={() => logout()} icon={<CiLogout />} aria-label={t('log_out')} mt="4" />
      </SidebarSection>
    )
  }

  return (
    <SidebarSection>
      {
        //! THIS IS FOR DEMO PURPOSES ONLY
        isTrunkUser && window.location.hostname === 'demo.dumbo.works' && (
          <Button
            variant="ghost"
            onClick={() => navigate('/demo')}
            my="4"
            leftIcon={<RiPresentationFill />}
          >
            {t('demo')}
          </Button>
        )
      }
      {
        //! THIS IS FOR STAGING PURPOSES ONLY
        isTrunkUser &&
          (window.location.hostname === 'staging.dumbo.works' ||
            window.location.hostname === 'localhost') && (
            <Button
              variant="ghost"
              onClick={() => navigate('/testing')}
              my="4"
              leftIcon={<GrTest />}
            >
              {t('testing')}
            </Button>
          )
      }
      {isTrunkUser && <LanguagePicker />}
      <Button variant="ghost" mt="4" p={0} onClick={() => logout()} leftIcon={<CiLogout />}>
        {t('log_out')}
      </Button>
    </SidebarSection>
  )
}
