import { Parser } from '@json2csv/plainjs'

export default function convertToCSV(objArray: any | any[]) {
  try {
    const parser = new Parser()
    const csv = parser.parse(objArray)
    return csv
  } catch (err) {
    console.error(err)
  }
}
