import { Switch, useToast } from '@chakra-ui/react'
import { ColumnDef, DataGrid } from '@saas-ui-pro/react'
import { useMutation } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { stationsQuery } from '~/atoms/stationsQuery'
import { useSaveToast } from '~/hooks/useSaveToast'
import { useUrlSyncedSorting } from '~/hooks/useUrlSyncedSorting'
import { Station, setAndonStations } from './api'

export default function AndonStationsSelection({
  hardwareId,
  andonStations,
  refetch,
}: {
  hardwareId: string
  andonStations?: Station[]
  refetch: () => void
}) {
  const { t } = useTranslation(['translation', 'devices', 'partners'])
  const toast = useToast()
  const [selectedStations, setSelectedStations] = useState<Station[]>(andonStations ?? [])
  const { showSaveChangesToast } = useSaveToast<Station[]>(false)

  const { sorting, setSorting } = useUrlSyncedSorting()

  const stations = useRecoilValue(stationsQuery)

  const handleChange = (newStations: Station[]) => {
    setSelectedStations(newStations)
    showSaveChangesToast({
      newData: newStations,
      onSave: (stations?: Station[]) => {
        if (!stations) return Promise.resolve()
        return mutation.mutate({ hardwareId, stations })
      },
    })
  }

  const mutation = useMutation({
    mutationFn: setAndonStations,
    onSuccess: () => {
      toast({
        title: t('update_success', { ns: 'translation' }),
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      refetch()
    },
    onError: (error: any) =>
      toast({
        title: t('update_failed', { ns: 'translation' }),
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      }),
  })

  const columns: ColumnDef<Station>[] = useMemo(
    () => [
      {
        id: 'partner_name',
        header: t('partner_name'),
      },
      {
        id: 'factory_name',
        header: t('factory_name'),
      },
      {
        id: 'zone_name',
        header: t('zone_name'),
      },
      {
        id: 'station_name',
        header: t('station_name'),
      },
      {
        id: 'station_name_1',
        header: t('enabled', { ns: 'translation' }),
        cell: (row) => {
          const rowStation = {
            partner_name: row.row.original.partner_name,
            factory_name: row.row.original.factory_name,
            zone_name: row.row.original.zone_name,
            station_name: row.row.original.station_name,
          }
          const selected = selectedStations?.some(
            (element) =>
              element.partner_name === rowStation.partner_name &&
              element.factory_name === rowStation.factory_name &&
              element.zone_name === rowStation.zone_name &&
              element.station_name === rowStation.station_name,
          )
          return (
            <Switch
              isChecked={selected ?? false}
              onChange={({ target }) => {
                let newStations
                if (target.checked) {
                  newStations = [...selectedStations, rowStation]
                } else {
                  newStations = selectedStations.filter(
                    (element) =>
                      !(
                        element.partner_name === rowStation.partner_name &&
                        element.factory_name === rowStation.factory_name &&
                        element.zone_name === rowStation.zone_name &&
                        element.station_name === rowStation.station_name
                      ),
                  )
                }
                handleChange(newStations)
              }}
            />
          )
        },
      },
    ],
    [selectedStations],
  )

  return (
    <DataGrid
      columnResizeEnabled
      layoutMode={'grow'}
      stickyHeader
      isSortable
      columns={columns}
      state={{
        // @ts-ignore
        sort: sorting,
      }}
      isHoverable={true}
      onSortChange={setSorting}
      pageCount={1}
      pageSize={stations?.length ?? 0}
      data={stations ?? []}
      slotProps={{
        row: {
          backgroundColor: 'white',
        },
        cell: {
          backgroundColor: 'white',
        },
        container: {
          overflow: 'scroll',
        },
      }}
    />
  )
}
