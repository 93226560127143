import { extendTheme, type ThemeConfig } from '@chakra-ui/react'
import { theme as proTheme } from '@saas-ui-pro/react'
import { baseTheme } from '@saas-ui/react'

import components from './components'

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

export const theme = extendTheme(
  {
    config,
    components,
    fontSizes: baseTheme.fontSizes,
    colors: baseTheme.colors,
  },
  proTheme,
)
