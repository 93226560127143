import {
  Box,
  Center,
  CircularProgress,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
} from '@chakra-ui/react'
import { Page, PageBody, PageHeader } from '@saas-ui-pro/react'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import Breadcrumbs from '~/components/Breadcrumbs'
import DetailsList from '~/components/DetailsList'
import AndonStationsSelection from './AndonStationsSelection'
import AndonsLogs from './AndonsLogs'
import { Andon, Station, andonDetailsQuery } from './api'

export default function AndonsDetailsPage() {
  const { t } = useTranslation(['translation', 'devices'])
  const { hardwareId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const { data, refetch } = useQuery(andonDetailsQuery(hardwareId!))

  function handleTabChanged(index: number) {
    setSearchParams((prev) => {
      prev.set('tab_index', index.toString())
      return prev
    })
  }

  return (
    <Page>
      <PageHeader title={data?.hardware_id} description={<Breadcrumbs />} />
      <PageBody contentWidth="full">
        <AndonsDetailsList data={data} />
        <Tabs
          isLazy
          defaultIndex={parseInt(searchParams.get('tab_index') ?? '0')}
          tabIndex={parseInt(searchParams.get('tab_index') ?? '0')}
          onChange={handleTabChanged}
          h="100%"
        >
          <TabList>
            <Tab>{t('stations', { count: 0, ns: 'translation' })}</Tab>
            <Tab>{t('logs', { ns: 'translation' })}</Tab>
          </TabList>
          <TabPanels h="100%">
            <TabPanel>
              {!data ? (
                <Center>
                  <CircularProgress isIndeterminate color="green.300" />
                </Center>
              ) : (
                <AndonStationsSelection
                  hardwareId={hardwareId!}
                  andonStations={data.andon_stations as Station[]}
                  refetch={refetch}
                />
              )}
            </TabPanel>
            <TabPanel h="100%">
              <AndonsLogs hardwareId={hardwareId!} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </PageBody>
    </Page>
  )
}

const AndonsDetailsList = ({ data }: { data: Andon | undefined }) => {
  const { t } = useTranslation(['devices', 'translation'])

  const connection_initiated_at = data?.connection_initiated_at
  const connection_initiated = connection_initiated_at ? (
    <Box>
      <Text>{dayjs(connection_initiated_at).format(`HH:mm`)}</Text>
      <Text>{dayjs(connection_initiated_at).format(`DD MMM YYYY`)}</Text>
    </Box>
  ) : (
    <Text>{t('never', { ns: 'translation' })}</Text>
  )
  const created_at_value = data?.created_at
  const created_at = (
    <Box>
      <Text>{dayjs(created_at_value).format(`HH:mm`)}</Text>
      <Text>{dayjs(created_at_value).format(`DD MMM YYYY`)}</Text>
    </Box>
  )
  const updated_at_value = data?.updated_at
  const updated_at = (
    <Box>
      <Text>{dayjs(updated_at_value).format(`HH:mm`)}</Text>
      <Text>{dayjs(updated_at_value).format(`DD MMM YYYY`)}</Text>
    </Box>
  )

  const details: [string | null, JSX.Element][] = [
    [t('device_id', { ns: 'devices' }), <Text>{data?.hardware_id ?? ''}</Text>],
    [
      t('is_connected', { ns: 'devices' }),
      <Tag colorScheme={data?.is_connected ? 'green' : 'red'}>
        {data?.is_connected ? 'connected' : 'disconnected'}
      </Tag>,
    ],
    [t('connected_at', { ns: 'devices' }), connection_initiated],
    [t('created_at', { ns: 'translation' }), created_at],
    [t('updated_at', { ns: 'translation' }), updated_at],
  ]
  return (
    <Box w={{ md: '50%', sm: '100%' }}>
      <DetailsList data={details} />
    </Box>
  )
}
