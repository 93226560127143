import { atom } from 'recoil'
import localStorageEffect from './effects/localStorageEffect'

export const partnerPicker = atom<
  { partner_name: string; partner_settings: Record<string, string | boolean> } | undefined
>({
  key: 'PartnerPicker',
  default: undefined,
  effects: [localStorageEffect('current_partner')],
})
