import { Select, SelectButton, SelectList } from '@saas-ui/react'
import i18next from 'i18next'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Language = {
  id: string
  name: string
}

const languages: Language[] = [
  {
    id: 'en',
    name: 'English UK',
  },
  {
    id: 'en-US',
    name: 'English US',
  },
  {
    id: 'de',
    name: 'German',
  },
  {
    id: 'es-419',
    name: 'Latin American Spanish',
  },
]

export default function LanguagePicker() {
  const { i18n } = useTranslation()
  const [currentLanguage, setCurrentLanguage] = useState('en')

  const changeLanguageHandler = (langID: string) => {
    i18n.changeLanguage(langID)
    setCurrentLanguage(langID)
  }

  useEffect(() => {
    setCurrentLanguage(i18next.language || window.localStorage.i18nextLng || 'en')
  }, [])

  return (
    <Select
      name="language-picker"
      value={currentLanguage}
      onChange={(value) => changeLanguageHandler(value as string)}
      options={languages.map((language) => ({
        value: language.id,
        label: language.name,
      }))}
    >
      <SelectButton />
      <SelectList zIndex="dropdown" />
    </Select>
  )
}
