import { selector } from 'recoil'
import { Database } from '~/types/database.types'
import { supabase } from '../utils/supabaseClient'
import { partnerPicker } from './partnerPicker'

export const factoriesQuery = selector({
  key: 'FactoriesList',
  get: async ({ get }) => {
    const partner = get(partnerPicker)
    if (!partner) return []

    const response = await supabase
      .from('factories')
      .select('partner_name, factory_name, factory_timezone')
      .eq('partner_name', partner.partner_name)
    return response.data as Database['public']['Tables']['factories']['Row'][]
  },
})
