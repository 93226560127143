import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { supabase } from '~/utils/supabaseClient'

export default function RequireMFA({ children }: { children: JSX.Element }) {
  const [readyToShow, setReadyToShow] = useState(false)
  const [showMFAScreen, setShowMFAScreen] = useState(false)
  const [requiredMFASetup, setRequiredMFASetup] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        const { data, error } = await supabase.auth.mfa.getAuthenticatorAssuranceLevel()
        if (error) {
          throw error
        }

        const { data: userDate, error: userError } = await supabase.auth.getUser()
        if (userError) {
          throw userError
        }

        const { data: factorsData, error: factorsError } = await supabase.auth.mfa.listFactors()
        if (factorsError) {
          throw factorsError
        }

        if (userDate.user.app_metadata?.mfa_required === true && factorsData.totp.length === 0) {
          setRequiredMFASetup(true)
        }
        if (data.nextLevel === 'aal2' && data.nextLevel !== data.currentLevel) {
          setShowMFAScreen(true)
        }
      } finally {
        setReadyToShow(true)
      }
    })()
  }, [])

  if (readyToShow) {
    if (requiredMFASetup) {
      // Redirect to MFA setup screen if MFA is required but not setup
      return <Navigate to="/mfa-setup" replace />
    }
    if (showMFAScreen) {
      // Redirect to MFA screen if MFA is required
      return <Navigate to="/mfa-challenge" replace />
    }

    return children
  }

  return <></>
}
