import { QueryClient } from '@tanstack/react-query'
import { UniclassCodesType } from '~/routes/production_planning_pages/products/ProductFamilies/api'
import { Database } from '~/types/database.types'
import { supabase } from '~/utils/supabaseClient'

export type ProductDetails = Database['public']['Tables']['bill_of_operations_versions']['Row'] & {
  profiles: {
    profile_first_name: string | null
    profile_last_name: string | null
  }
  uniclass_codes: UniclassCodesType
  bills_of_operations: {
    product_id: string | null
  }
}

export const productDetailsQuery = (id: string, version?: string) => ({
  queryKey: ['product', 'detail', id, version],
  queryFn: async () => {
    const request = supabase.from('bill_of_operations_versions').select(
      `
        *,
        profiles(
          profile_first_name,
          profile_last_name
        ),
        bills_of_operations(product_id),
        uniclass_codes(*)
      `,
    )
    if (version) {
      request.eq('bill_of_operations_version', version)
    } else {
      request.eq('is_current_version', true)
    }
    const response = await request
      .match({
        bill_of_operations_id: id,
      })
      .maybeSingle()

    if (!response.data) {
      throw new Response('', {
        status: 404,
        statusText: 'Not Found',
      })
    }
    return response.data as ProductDetails
  },
})

export type BillOfOperationsDetails = {
  bill_of_operations_id: string
  bill_of_operations_name?: string
  bill_of_operations_key?: string
  bill_of_operations_uniclass_code_id?: string
  bill_of_operations_dispatch_checks?: {
    order: number
    name: string
  }[]
  operations?: {
    operation_id: string
    operation_version: number
    operation_order: number
  }[]
}

export const updateBillOfOperations = async (newData: ProductDetails) => {
  try {
    const { error } = await supabase.functions.invoke('update-bill-of-operations', {
      body: { ...newData },
    })
    if (error) {
      throw error
    }
    return true
  } catch {
    return false
  }
}
export const updateBillsOfOperationsWorkOrdersToLatestVersion = async (
  billsOfOperationsIds: string[],
) => {
  const { error } = await supabase.functions.invoke(
    'update-bills-of-operations-work-orders-to-latest-version',
    {
      body: { bill_of_operations_ids: billsOfOperationsIds },
    },
  )
  if (error) {
    throw error
  }
}

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: any) => {
    const query = productDetailsQuery(params.billOfOperationsId, params.billOfOperationsVersion)
    return queryClient.getQueryData(query as any) ?? (await queryClient.fetchQuery(query))
  }
