import { QueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import JobState from '~/types/JobState.types'
import { CheckStatus, CheckType, EvidenceType } from '~/types/ProcessCheck.types'
import { Database } from '~/types/database.types'
import { supabase } from '~/utils/supabaseClient'
dayjs.extend(utc)

export type QualityCheck = {
  job_card_id: string
  job_card_current_status: JobState
  job: string
  in_process_check: {
    check?: string
    content?: string
    name: string
    order: number
    type: CheckType
    images: { path: string; title: string }[]
    evidence_files:
      | {
          path: string
          title: string
          bucket: string
        }[]
      | null
    check_status?: CheckStatus | null
    addressed_on?: string | null
    required_evidence: EvidenceType[]
    measurement_unit?: string
    evidence_measurement?: number
    threshold?: {
      lower_threshold: number
      upper_threshold: number
    }
    expiry_date: string
  }
  signer?: string
  work_order_id: string
  work_order_name: string
  bill_of_operations_name: string
  station?: string
  project?: string
}

export const jobQualityCheckQuery = (jobCardId?: string, orderNumber?: number) => ({
  queryKey: ['quality', 'check', jobCardId, orderNumber],
  queryFn: async () => {
    const { data } = await supabase
      .rpc('get_in_process_check_details', {
        pin_job_card_id: jobCardId!,
        pin_check_order: orderNumber!,
      })
      .single()
    return data as QualityCheck | null
  },
})

export type CheckTimelineEvent = Database['public']['Tables']['job_events']['Row'] & {
  job_cards: {
    job_card_id: string
    workers: {
      worker_first_name: string
      worker_last_name: string
    }
  } | null
  job_event_profile:
    | {
        id: string
        profile_first_name: string | null
        profile_last_name: string | null
      }
    | undefined
}

export const checkTimelineQuery = (jobCardId: string, checkOrderNumber: number) => ({
  queryKey: ['quality', 'check', 'timeline', jobCardId, checkOrderNumber],
  queryFn: async () => {
    const { data } = await supabase
      .from('job_events')
      .select(
        `
      *,
      job_cards!inner(
        job_card_id,
        workers(
          worker_first_name,
          worker_last_name
        )
      )
    `,
      )
      .order('job_event_occurrence', { ascending: false })
      .eq('job_cards.job_card_id', jobCardId)
      .eq('job_event_metadata->in_process_check->order', checkOrderNumber)
    // Get profiles
    const profileIds = data?.map((event) => event.job_event_user)
    let profiles: {
      id: string
      profile_first_name: string | null
      profile_last_name: string | null
    }[] = []
    if (profileIds) {
      const profilesResponse = await supabase
        .from('profiles')
        .select('id, profile_first_name, profile_last_name')
        .in('id', profileIds!)
      profiles = profilesResponse.data ?? []
    }
    const dataWithProfiles = data?.map((event) => {
      const profile = profiles.find((profile) => profile.id === event.job_event_user)
      return {
        ...event,
        job_event_profile: profile,
      }
    })
    return dataWithProfiles as CheckTimelineEvent[] | null
  },
})

export type CheckStatusMutationParams = {
  jobCardId: string
  orderNumber: number
  checkStatus: CheckStatus
}
export const checkStatusMutation = async ({
  jobCardId,
  orderNumber,
  checkStatus,
}: CheckStatusMutationParams) => {
  const response = await supabase.functions.invoke(
    'watchtower-submit-multiple-in-process-check-changed',
    {
      body: {
        checks: [
          {
            job_card_id: jobCardId,
            in_process_check_order_number: orderNumber,
            check_status: checkStatus,
          },
        ],
        // Get current time with timezone offset
        addressed_on: dayjs.utc().local().format(),
      },
    },
  )
  if (response.error) throw new Error(response.error.message)
  return response.data
}

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: { params: { [key: string]: any } }) => {
    const query = jobQualityCheckQuery(params.jobCardId, params.orderNumber)
    return queryClient.getQueryData(query as any) ?? (await queryClient.fetchQuery(query))
  }

export const submitCheckMutation = async (formData: FormData) => {
  const session = await supabase.auth.getSession()
  const response = await fetch(
    `${import.meta.env.VITE_APP_SUPABASE_URL}/functions/v1/watchtower-submit-in-process-check-changed`,
    {
      method: 'POST',
      headers: {
        apikey: import.meta.env.VITE_APP_SUPABASE_ANON_KEY,
        authorization: `Bearer ${session.data.session?.access_token}`,
      },
      body: formData,
    },
  )
  if (!response.ok) {
    throw new Error('Failed to submit check')
  }
  return 'success'
}
