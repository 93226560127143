import { Filter } from '@saas-ui-pro/react'
import { PaginationState, SortingState } from '@tanstack/react-table'
import { selector } from 'recoil'
import { partnerPicker } from '~/atoms/partnerPicker'
import { scheduled_states } from '~/types/JobState.types'
import { Database } from '~/types/database.types'
import {
  addBooleanFilters,
  addDateRangeFilters,
  addStringValueFilter,
  getMultiSelectFilters,
} from '~/utils/FilterUtils/filtersBuilding'
import convertToCSV from '~/utils/convertToCSV'
import { supabase } from '~/utils/supabaseClient'
import SupabaseQueryHelper from '~/utils/supabaseQueryHelper'
import { findTagByCategory } from '~/utils/tagHelpers'
import { predefinedTimeSlotsFilter } from '../utils/FilterUtils/filtersBuilding'

export type Job = Database['base']['Views']['job_cards_index']['Row']

export type RangeType = 'job_card_started_at' | 'job_card_completed_at'

export type JobsQueryOptions = {
  partner: string
  factory?: string
  workOrderId?: string
  /**
   * If true, archived work orders will be excluded from the query
   * @default true
   */
  withOutArchived?: boolean
  pagination?: PaginationState
  sorting?: SortingState
  activeFilters?: Filter[]
}

export const jobsQuery = async ({ withOutArchived = true, ...options }: JobsQueryOptions) => {
  const query = supabase.rpc(
    'get_job_cards_index_by_partner',
    {
      partner_name: options.partner,
    },
    { count: 'exact' },
  )

  // Filters
  if (withOutArchived) {
    query.is('work_order_is_archived', false)
  }
  if (options.partner) {
    query.eq('partner_name', options.partner)
  }
  if (options.factory) {
    query.eq('factory_name', options.factory)
  }
  if (options.workOrderId) {
    query.eq('work_order_id', options.workOrderId)
  }

  // Sorting and pagination
  if (options.sorting && options.sorting.length > 0) {
    SupabaseQueryHelper.addSorting(query, options.sorting)
  } else {
    query.order('job_card_completed_at', { ascending: false })
  }
  if (options.pagination) {
    SupabaseQueryHelper.addPagination(query, options.pagination)
  }

  // Search with filters
  if (options?.activeFilters?.length ?? 0 > 0) {
    // Job Card Name filter
    addStringValueFilter(query, options.activeFilters ?? [], 'jobCardName', 'operation_name')

    // Work Order Name filter
    addStringValueFilter(query, options?.activeFilters ?? [], 'workOrderName', 'work_order_name')

    // Products filters
    const productsFilters = getMultiSelectFilters(options?.activeFilters ?? [], 'products')
    if (productsFilters.length > 0) {
      query.in('bill_of_operations_name', productsFilters ?? [])
    }

    // Status Filter
    const checkStatusFilters = getMultiSelectFilters(options?.activeFilters ?? [], 'status')
    if (checkStatusFilters?.length > 0) {
      if (checkStatusFilters.includes('scheduled')) {
        checkStatusFilters.push('canceled')
      }
      if (checkStatusFilters.includes('in_progress')) {
        checkStatusFilters.push('started')
        checkStatusFilters.push('restarted')
      }
      query.in('job_card_current_status', checkStatusFilters ?? [])
    }

    // Stations filter
    const checkStationsFilters = getMultiSelectFilters(options?.activeFilters ?? [], 'stations')
    if (checkStationsFilters?.length > 0) {
      const factoriesSet = new Set(
        checkStationsFilters.map((station) => JSON.parse(station as string).factory_name),
      )
      const zonesSet = new Set(
        checkStationsFilters.map((station) => JSON.parse(station as string).zone_name),
      )
      const stationsSet = new Set(
        checkStationsFilters.map((station) => JSON.parse(station as string).station_name),
      )
      query.in('factory_name', [...factoriesSet])
      query.in('zone_name', [...zonesSet])
      query.in('station_name', [...stationsSet])
    }

    // Started on filter
    addDateRangeFilters(query, options?.activeFilters ?? [], 'startedOn', 'job_card_started_at')

    // Completed on filter
    addDateRangeFilters(query, options?.activeFilters ?? [], 'completedOn', 'job_card_completed_at')

    // Is final Job Card filter
    addBooleanFilters(query, options?.activeFilters ?? [], 'isFinal', 'job_card_final')

    predefinedTimeSlotsFilter(
      query,
      options?.activeFilters,
      'completedOnPredefined',
      'job_card_completed_at',
    )
  }

  // Request
  const { data, count } = await query.order('job_card_order')
  return {
    rows: data as Job[] | null,
    pageCount: SupabaseQueryHelper.getPageCount(count, options.pagination),
  }
}

export type WorkOrderTaggings = Database['public']['Tables']['work_order_taggings']['Row']
export type WorkOrder = {
  work_order_name: string
  bill_of_operations_versions: {
    bill_of_operations_name: string
  }
  work_order_taggings: Array<WorkOrderTaggings>
}
export type JobsQueryCSVOptions = {
  partner?: string
  workOrderId?: string
  sorting?: SortingState
  activeFilters?: Filter[]
}

export const jobsCSVQuery = async (options: JobsQueryCSVOptions) => {
  const query = supabase
    .from('job_cards')
    .select(
      `
    *,
    operation_versions!inner (
     operation_name
    ),
    work_orders!inner (
      work_order_name,
      bill_of_operations_versions (
        bill_of_operations_name
      ),
      work_order_taggings (*),
      is_archived
    )
`,
    )
    .is('work_orders.is_archived', false)

  // Filters
  if (options.partner) {
    query.eq('partner_name', options.partner)
  }
  if (options.workOrderId) {
    query.eq('work_order_id', options.workOrderId)
  }

  // Search with filters
  if (options?.activeFilters?.length ?? 0 > 0) {
    // Job Card Name filter
    addStringValueFilter(query, options?.activeFilters ?? [], 'jobCardName', 'operation_name')

    // Work Order Name filter
    addStringValueFilter(query, options?.activeFilters ?? [], 'workOrderName', 'work_order_name')

    // Status Filter
    const checkStatusFilters = getMultiSelectFilters(options?.activeFilters ?? [], 'status')
    if (checkStatusFilters?.length > 0) {
      if (checkStatusFilters.includes('scheduled')) {
        checkStatusFilters.push('canceled')
      }
      if (checkStatusFilters.includes('in_progress')) {
        checkStatusFilters.push('started')
        checkStatusFilters.push('restarted')
      }

      query.in('job_card_current_status', checkStatusFilters ?? [])
    }

    // Stations filter
    const checkStationsFilters = getMultiSelectFilters(options?.activeFilters ?? [], 'stations')
    if (checkStationsFilters?.length > 0) {
      const factoriesSet = new Set(
        checkStationsFilters.map((station) => JSON.parse(station as string).factory_name),
      )
      const zonesSet = new Set(
        checkStationsFilters.map((station) => JSON.parse(station as string).zone_name),
      )
      const stationsSet = new Set(
        checkStationsFilters.map((station) => JSON.parse(station as string).station_name),
      )
      query.in('factory_name', [...factoriesSet])
      query.in('zone_name', [...zonesSet])
      query.in('station_name', [...stationsSet])
    }

    // Started on filter
    addDateRangeFilters(query, options?.activeFilters ?? [], 'startedOn', 'job_card_started_at')

    // Completed on filter
    addDateRangeFilters(query, options?.activeFilters ?? [], 'completedOn', 'job_card_completed_at')

    // Is final Job Card filter
    addBooleanFilters(query, options?.activeFilters ?? [], 'isFinal', 'job_card_final')
  }

  // Sorting and pagination
  if (options.sorting && options.sorting.length > 0) {
    SupabaseQueryHelper.addSorting(query, options.sorting)
  }

  const { data, error } = await query.order('job_card_started_at')
  const csv = convertToCSV(
    data?.map((row) => {
      const workOrder = row.work_orders as WorkOrder | null
      return {
        job: row.job_card_operation_name ?? (row.operation_versions as any).operation_name,
        work_order_id: (row.work_orders as any).work_order_name ?? '',
        product: (row.work_orders as any).bill_of_operations_versions.bill_of_operations_name ?? '',
        plot:
          findTagByCategory({
            tags: workOrder?.work_order_taggings ?? [],
            tagCategory: 'Plot',
            categoryKey: 'work_order_tag_category',
          })?.work_order_tag ?? '',
        site:
          findTagByCategory({
            tags: workOrder?.work_order_taggings ?? [],
            tagCategory: 'Site Code',
            categoryKey: 'work_order_tag_category',
          })?.work_order_tag ?? '',
        headcount: row.job_card_latest_headcount ?? '',
        cycle_time: row.job_card_cycle_time ?? '',
        total_overrun_time: row.job_card_overrun_time ?? '',
        total_downtime: row.job_card_downtime ?? '',
        zone: row.zone_name ?? '',
        station: row.station_name ?? '',
        status: row.job_card_current_status ?? '',
        start_at: row.job_card_started_at ?? '',
        complete_at: row.job_card_completed_at ?? '',
      }
    }),
  )
  return {
    csv,
    error,
  }
}

export const jobsTotalCountQuery = selector({
  key: 'JobsTotalCountQuery',
  get: async ({ get }) => {
    const response = await supabase
      .from('job_cards')
      .select('partner_name, work_orders!inner(is_archived)', { count: 'exact', head: true })
      .eq('partner_name', get(partnerPicker)!.partner_name)
      .is('work_orders.is_archived', false)
    return response.count
  },
})

export const jobsScheduledCountQuery = selector({
  key: 'JobsScheduledCountQuery',
  get: async ({ get }) => {
    const response = await supabase
      .from('job_cards')
      .select('partner_name, job_card_current_status, work_orders!inner(is_archived)', {
        count: 'exact',
        head: true,
      })
      .eq('partner_name', get(partnerPicker)!.partner_name)
      .in('job_card_current_status', scheduled_states)
      .is('work_orders.is_archived', false)
    return response.count
  },
})

export const jobsInProcessCountQuery = selector({
  key: 'JobsInProcessCountQuery',
  get: async ({ get }) => {
    const response = await supabase
      .from('job_cards')
      .select('partner_name, job_card_current_status, work_orders!inner(is_archived)', {
        count: 'exact',
        head: true,
      })
      .eq('partner_name', get(partnerPicker)!.partner_name)
      .in('job_card_current_status', ['started', 'in_progress'])
      .is('work_orders.is_archived', false)
    return response.count
  },
})

export const jobsOnBreakCountQuery = selector({
  key: 'JobsOnBreakCountQuery',
  get: async ({ get }) => {
    const response = await supabase
      .from('job_cards')
      .select('partner_name, job_card_current_status, work_orders!inner(is_archived)', {
        count: 'exact',
        head: true,
      })
      .eq('partner_name', get(partnerPicker)!.partner_name)
      .in('job_card_current_status', ['on_break'])
      .is('work_orders.is_archived', false)
    return response.count
  },
})

export const jobsDowntimeCountQuery = selector({
  key: 'JobsDowntimeCountQuery',
  get: async ({ get }) => {
    const response = await supabase
      .from('job_cards')
      .select('partner_name, job_card_current_status, work_orders!inner(is_archived)', {
        count: 'exact',
        head: true,
      })
      .eq('partner_name', get(partnerPicker)!.partner_name)
      .in('job_card_current_status', ['downtime'])
      .is('work_orders.is_archived', false)
    return response.count
  },
})

export const jobsClockedOutCountQuery = selector({
  key: 'JobsClockedOutCountQuery',
  get: async ({ get }) => {
    const response = await supabase
      .from('job_cards')
      .select('partner_name, job_card_current_status, work_orders!inner(is_archived)', {
        count: 'exact',
        head: true,
      })
      .eq('partner_name', get(partnerPicker)!.partner_name)
      .in('job_card_current_status', ['clocked_out'])
      .is('work_orders.is_archived', false)
    return response.count
  },
})
export const jobsCompleteCountQuery = selector({
  key: 'JobsCompleteCountQuery',
  get: async ({ get }) => {
    const response = await supabase
      .from('job_cards')
      .select('partner_name, job_card_current_status, work_orders!inner(is_archived)', {
        count: 'exact',
        head: true,
      })
      .eq('partner_name', get(partnerPicker)!.partner_name)
      .in('job_card_current_status', ['complete'])
      .is('work_orders.is_archived', false)
    return response.count
  },
})
export const jobsSignedOffCountQuery = selector({
  key: 'JobsSignedOffCountQuery',
  get: async ({ get }) => {
    const response = await supabase
      .from('job_cards')
      .select('partner_name, job_card_current_status, work_orders!inner(is_archived)', {
        count: 'exact',
        head: true,
      })
      .eq('partner_name', get(partnerPicker)!.partner_name)
      .in('job_card_current_status', ['job_signed_off'])
      .is('work_orders.is_archived', false)
    return response.count
  },
})
