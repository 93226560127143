import { selector } from 'recoil'
import { supabase } from '../utils/supabaseClient'
import { factoryPicker } from './factoryPicker'
import { partnerPicker } from './partnerPicker'

export const stationsQuery = selector({
  key: 'Stations',
  get: async ({ get }) => {
    const query = supabase
      .from('stations')
      .select('*')
      .eq('partner_name', get(partnerPicker)!.partner_name)
      .order('partner_name')
      .order('factory_name')
      .order('zone_name')
      .order('station_name')
    const factory = get(factoryPicker)
    if (factory) {
      query.eq('factory_name', factory)
    }
    const response = await query
    return response.data
  },
})
