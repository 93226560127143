import { QueryClient } from '@tanstack/react-query'
import { PaginationState, SortingState } from '@tanstack/react-table'
import { selector } from 'recoil'
import { jobsQuery, JobsQueryOptions } from '~/api/jobsApi'
import { Database } from '~/types/database.types'
import JobState from '~/types/JobState.types'
import { supabase } from '~/utils/supabaseClient'
import SupabaseQueryHelper from '~/utils/supabaseQueryHelper'

export type WorkOrderTaggings = Database['public']['Tables']['work_order_taggings']['Row']
export type WorkOrderDetails = Database['public']['Tables']['work_orders']['Row'] & {
  bill_of_operations_versions: {
    bill_of_operations_id: string
    bill_of_operations_version: number
    bill_of_operations_name: string
  }
} & {
  job_cards: Array<{
    job_card_current_status: JobState
  }>
} & {
  work_order_taggings: Array<WorkOrderTaggings>
}

export const workOrderDetailQuery = (id: string) => ({
  queryKey: ['work-order', 'detail', id],
  queryFn: async () => {
    const response = await await supabase
      .from('work_orders')
      .select(
        `
      *,
      bill_of_operations_versions (
        bill_of_operations_id,
        bill_of_operations_version,
        bill_of_operations_name
      ),
      work_order_taggings(*),
      job_cards (
          job_card_current_status
      )
      `,
      )
      .eq('work_order_id', id)
      .maybeSingle()
    if (!response.data) {
      throw new Response('', {
        status: 404,
        statusText: 'Not Found',
      })
    }
    return response.data as WorkOrderDetails
  },
})

export const workOrderJobsQuery = (fetchDataOptions: JobsQueryOptions) => ({
  queryKey: ['work-order', 'jobs', fetchDataOptions],
  queryFn: () => jobsQuery(fetchDataOptions),
})

export type Drawing = Database['public']['Tables']['drawings']['Row']

export const workOrderDrawingsQuery = ({
  workOrderId,
  pagination,
  sorting,
}: {
  workOrderId: string
  pagination?: PaginationState
  sorting?: SortingState
}) => ({
  queryKey: ['work-order', 'drawings', workOrderId],
  queryFn: async () => {
    const query = supabase
      .from('drawings')
      .select(
        `*,
        work_order_drawings!inner(*),
        products(*),
        updated_by:profiles!drawings_drawing_updated_by_fkey!inner(profile_first_name, profile_last_name),
        created_by:profiles!drawings_drawing_created_by_fkey(profile_first_name, profile_last_name)`,
        { count: 'exact' },
      )
      .eq('work_order_drawings.work_order_id', workOrderId)

    // Sorting and pagination
    if (sorting && sorting.length > 0) {
      SupabaseQueryHelper.addSorting(query, sorting)
    }
    if (pagination) {
      SupabaseQueryHelper.addPagination(query, pagination)
    }

    const { data, count } = await query
    return {
      rows: data as Drawing[] | null,
      pageCount: SupabaseQueryHelper.getPageCount(count, pagination),
    }
  },
})

export const workOrderTagCategoriesQuery = selector({
  key: 'WorkOrderTagCategories',
  get: async () => {
    const response = await supabase.from('work_order_tag_categories').select('*')
    return response.data
  },
})

export const workOrderTagsQuery = async ({
  partner_name,
  work_order_tag_category,
}: {
  partner_name: string
  work_order_tag_category: string
}) => {
  const response = await supabase.from('work_order_tags').select('*').match({
    partner_name,
    work_order_tag_category,
  })
  return response.data
}

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: any) => {
    const query = workOrderDetailQuery(params.workOrderId)
    return queryClient.getQueryData(query as any) ?? (await queryClient.fetchQuery(query))
  }
