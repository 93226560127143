import { useFeatures } from '@saas-ui-pro/feature-flags'
import type { Session } from '@supabase/supabase-js'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useEffect } from 'react'
import { useIntercom } from 'react-use-intercom'
import { useSetRecoilState } from 'recoil'
import { supabase } from '~/utils/supabaseClient'
import AppRouter from './AppRouter'
import sessionStorage from './atoms/sessionStorage'
import './style.css'

function App() {
  const setSession = useSetRecoilState(sessionStorage)
  const { update } = useIntercom()
  const features = useFeatures()

  const clearUser = () => {
    update()
    localStorage.clear()
  }

  const setUser = (session: Session) => {
    const userId = session.user.id
    const email = session.user.email
    const name = session.user.user_metadata?.first_name
      ? `${session.user.user_metadata?.first_name} ${session.user.user_metadata?.last_name}`
      : undefined
    update({
      userId,
      email,
      name,
    })
    const userIdentify = {
      userId,
      admin: false,
    }
    if (
      email?.includes('@trunk.works') ||
      email?.includes('@artic.works') ||
      email?.includes('@bandk.co.uk')
    ) {
      userIdentify.admin = true
    }
    features.identify(userIdentify)
  }

  const onSessionChange = (session: Session | null) => {
    if (!session) {
      clearUser()
    } else {
      setUser(session)
    }
    setSession(session)
  }

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => onSessionChange(session))
    supabase.auth.onAuthStateChange((_event, session) => onSessionChange(session))
  }, [])

  return (
    <>
      <AppRouter />
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  )
}

export default App
