import { Filter } from '@saas-ui-pro/react'
import { QueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { Database } from '~/types/database.types'
import { supabase } from '~/utils/supabaseClient'

export type Station = {
  partner_name: string
  factory_name: string
  zone_name: string
  station_name: string
}
export type Andon = Database['public']['Tables']['andons_devices']['Row']

export const andonDetailsQuery = (id: string) => ({
  queryKey: ['device', 'details', id],
  queryFn: async () => {
    const response = await supabase.from('andons_devices').select().eq('hardware_id', id).single()
    if (!response.data) {
      throw new Response('', {
        status: 404,
        statusText: 'Not Found',
      })
    }
    return response.data as Andon
  },
})

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: any) => {
    const query = andonDetailsQuery(params.hardwareId)
    return queryClient.getQueryData(query as any) ?? (await queryClient.fetchQuery(query))
  }

export const setAndonStations = async ({
  hardwareId,
  stations,
}: {
  hardwareId: string
  stations: Station[]
}) => {
  const { data, error } = await supabase
    .from('andons_devices')
    .update({
      andon_stations: stations.map((station) => ({
        partner_name: station.partner_name,
        factory_name: station.factory_name,
        zone_name: station.zone_name,
        station_name: station.station_name,
      })),
      updated_at: dayjs().toISOString(),
    })
    .eq('hardware_id', hardwareId)
  if (error) {
    throw error
  }
  return data
}

export type AndonLog = Database['public']['Tables']['andons_log']['Row']
export type ConnectionStatus = Database['public']['Enums']['connection_status']

export const getAndonLogs = (hardwareId: string, status?: Filter[] | null) => ({
  queryKey: ['andons', 'logs', hardwareId, ...(status ?? [])],
  queryFn: async () => {
    const query = supabase
      .from('andons_log')
      .select()
      .eq('hardware_id', hardwareId)
      .order('occurrence', { ascending: false })

    const includeStatus = status?.filter((filter) => filter.operator === 'is')
    if (includeStatus) {
      query.in('connection_status', includeStatus.map((v) => v.value) as ConnectionStatus[])
    }
    const excludeStatus = status?.filter((filter) => filter.operator === 'isNot')
    if (excludeStatus) {
      query.not('connection_status', 'in', `(${excludeStatus.map((v) => v.value).join(',')})`)
    }

    const { data, error } = await query
    if (error) {
      throw error
    }
    return data
  },
})
