import { QueryClient } from '@tanstack/react-query'
import { PaginationState, SortingState } from '@tanstack/react-table'
import { Database } from '~/types/database.types'
import { supabase } from '~/utils/supabaseClient'
import SupabaseQueryHelper from '~/utils/supabaseQueryHelper'

export type Worker = Database['public']['Tables']['workers']['Row']

export type WorkerEngagement = Database['public']['Tables']['worker_engagement']['Row'] & {
  workers: Worker
}

export type WorkerEngagementOptions = {
  workerId: string
  date: string
  pagination?: PaginationState
  sorting?: SortingState
}

export const getWorkerEngagement = (options: WorkerEngagementOptions) => ({
  queryKey: [
    'worker-engagement',
    'details',
    options.workerId,
    options.date,
    options.pagination,
    options.sorting,
  ],
  queryFn: async () => {
    const requestWorker = supabase
      .from('worker_engagement')
      .select(
        `
      *,
      workers(*)`,
      )
      .eq('worker_id', options.workerId)
      .eq('work_date', options.date)
      .single()

    const { data: workerData, error: workerError } = await requestWorker

    const partner = workerData?.partner_name
    const query = supabase
      .rpc(
        'get_job_cards_index_by_partner',
        {
          partner_name: partner!,
        },
        { count: 'exact' },
      )
      .in('job_card_id', workerData?.worked_job_ids ?? [])

    // Sorting and pagination
    if (options.sorting && options.sorting.length > 0) {
      SupabaseQueryHelper.addSorting(query, options.sorting)
    }
    if (options.pagination) {
      SupabaseQueryHelper.addPagination(query, options.pagination)
    }
    const { data, count, error } = await query

    return {
      workerEngagement: workerData as WorkerEngagement,
      rows: data,
      pageCount: SupabaseQueryHelper.getPageCount(count, options.pagination),
      error: workerError || error,
    }
  },
})

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: any) => {
    const query = getWorkerEngagement({ workerId: params.workerId, date: params.date })
    return queryClient.getQueryData(query as any) ?? (await queryClient.fetchQuery(query))
  }
