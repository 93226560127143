import { createClient } from '@supabase/supabase-js'

const supabaseDemo = createClient(
  import.meta.env.VITE_APP_SUPABASE_URL,
  import.meta.env.VITE_APP_SUPABASE_ANON_KEY,
  {
    db: { schema: 'demo' },
  },
)

// Helpers
export const newDemoWorkOrder = async () => {
  const { data, error } = await supabaseDemo.rpc('setup_demo_work_order')
  if (error) {
    throw new Error(error.message)
  }
  return data
}

// Scenarios
export const runScenario1 = async () => {
  const { data, error } = await supabaseDemo.rpc('demo_scenario_1')
  if (error) {
    throw new Error(error.message)
  }
  return data
}
