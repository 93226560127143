import { Select, SelectButton, SelectList } from '@saas-ui/react'
import React, { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { partnerPicker } from '~/atoms/partnerPicker'
import { partnersQuery } from '~/atoms/partnersQuery'

const PartnerPicker = () => {
  const partnersList = useRecoilValue(partnersQuery)
  const [partnerPickerState, setPartnersPickerState] = useRecoilState(partnerPicker)

  useEffect(() => {
    if (
      partnersList?.at(0) &&
      (partnerPickerState === undefined || typeof partnerPickerState !== 'object')
    ) {
      setPartnersPickerState({
        partner_name: partnersList.at(0)!.partner_name,
        partner_settings: partnersList.at(0)!.partner_settings as Record<string, string | boolean>,
      })
    }
  }, [partnersList])

  const handlePartnerChange = (partnerName: string) => {
    const partner = partnersList?.find((partner) => partner.partner_name === partnerName)
    setPartnersPickerState({
      partner_name: partnerName,
      partner_settings: partner?.partner_settings as Record<string, string | boolean>,
    })
  }

  return partnersList && partnersList.length > 1 ? (
    <Select
      name="partner-picker"
      value={partnerPickerState?.partner_name}
      onChange={(value) => handlePartnerChange(value as string)}
      options={partnersList?.map((partner) => ({
        value: partner.partner_name,
        label: partner.partner_display_name!,
      }))}
    >
      <SelectButton />
      <SelectList zIndex="dropdown" />
    </Select>
  ) : null
}

export default () => (
  <React.Suspense>
    <PartnerPicker />
  </React.Suspense>
)
