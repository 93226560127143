import { Badge, Flex, Text } from '@chakra-ui/react'
import {
  ActiveFiltersList,
  Filter,
  FilterItem,
  FiltersAddButton,
  FiltersProvider,
  Toolbar,
} from '@saas-ui-pro/react'
import {
  Timeline,
  TimelineContent,
  TimelineIcon,
  TimelineItem,
  TimelineSeparator,
  TimelineTrack,
} from '@saas-ui/react'
import { REALTIME_SUBSCRIBE_STATES } from '@supabase/supabase-js'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiCircle } from 'react-icons/fi'
import SubscriptionIndicator from '~/components/SubscriptionIndicator'
import { supabase } from '~/utils/supabaseClient'
import { AndonLog, getAndonLogs } from './api'

export default function AndonsLogs({ hardwareId }: { hardwareId: string }) {
  const { t } = useTranslation(['translation', 'devices'])
  const [subscriptionState, setSubscriptionState] = useState<REALTIME_SUBSCRIBE_STATES | null>(null)
  const [filter, setFilter] = useState<Filter[] | null>(null)

  const filters = useMemo<FilterItem[]>(
    () => [
      {
        id: 'status',
        label: 'Status',
        icon: <FiCircle />,
        type: 'enum',
        items: [
          {
            id: 'connected',
            label: 'Connected',
            icon: <Badge boxSize="8px" mx="2px" borderRadius="full" bg="green.400" />,
          },
          {
            id: 'disconnected',
            label: 'Disconnected',
            icon: <Badge boxSize="8px" mx="2px" borderRadius="full" bg="red.400" />,
          },
          {
            id: 'heartbeat',
            label: 'Heartbeat',
            icon: <Badge boxSize="8px" mx="2px" borderRadius="full" bg="primary.500" />,
          },
        ],
      },
    ],
    [],
  )
  const onFilter = useCallback((filters: Filter[]) => {
    setFilter(filters)
  }, [])

  const { data, refetch } = useQuery(getAndonLogs(hardwareId, filter))

  useEffect(() => {
    supabase
      .channel('andons_log')
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'andons_logs',
          filter: `hardware_id=eq.${hardwareId}`,
        },
        async () => {
          try {
            await refetch()
          } catch (error) {
            console.error({ error })
          }
        },
      )
      .subscribe((status) => {
        console.debug({ status })
        setSubscriptionState(status as REALTIME_SUBSCRIBE_STATES)
      })
  }, [hardwareId])

  return (
    <FiltersProvider filters={filters} onChange={onFilter}>
      <Toolbar variant="outline" justifyContent={'space-between'}>
        <FiltersAddButton />
        <SubscriptionIndicator subscriptionState={subscriptionState} />
      </Toolbar>
      <ActiveFiltersList />
      <Timeline overflow={'scroll'} h="100%">
        {data?.map((log: AndonLog) => (
          <TimelineItem key={log.occurrence}>
            <TimelineSeparator>
              <TimelineIcon
                color={
                  log.connection_status === 'connected'
                    ? 'green.500'
                    : log.connection_status === 'disconnected'
                      ? 'red.500'
                      : 'primary.500'
                }
              />
              <TimelineTrack />
            </TimelineSeparator>
            <TimelineContent>
              <Flex>
                <Text textTransform="uppercase">{log.connection_status}</Text> -
                {dayjs(log.occurrence).format('DD.MM.YYYY hh:mm')}
              </Flex>
              {(log.metadata as Record<string, any>).app_version && (
                <Text fontSize="sm" color="gray.500">
                  {t('app_version')}: {(log.metadata as Record<string, any>)?.app_version ?? ''}
                </Text>
              )}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </FiltersProvider>
  )
}
