import { Navigate } from 'react-router-dom'
import { RecoilValueReadOnly } from 'recoil'

export default function RequireFeatureFlag({
  children,
  flag,
}: {
  children: JSX.Element
  flag: boolean | RecoilValueReadOnly<boolean>
}) {
  if (!flag) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" replace />
  }

  return children
}
