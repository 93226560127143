// toastUtils.ts
import { useToast } from '@chakra-ui/react' // Import the useToast hook

export const useShowToast = () => {
  const toast = useToast()

  const showToast = (
    message: string,
    status: 'success' | 'error' | 'warning' | 'info' | 'loading',
  ) => {
    toast({
      title: message,
      status: status,
      isClosable: true,
    })
  }

  return showToast
}
