import { ColumnSort, SortingState, Updater } from '@tanstack/react-table'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

interface useUrlSyncedSortingReturn {
  sorting: SortingState
  setSorting: (updaterOrValue: Updater<ColumnSort[]>) => void
}

export const useUrlSyncedSorting = (): useUrlSyncedSortingReturn => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [sorting, setSorting] = useState<SortingState>(
    searchParams.has('sorting')
      ? [
          {
            id: searchParams.get('sorting')!.split(':')[0],
            desc: searchParams.get('sorting')!.split(':')[1] === 'desc',
          },
        ]
      : [],
  )

  useEffect(() => {
    setSearchParams((prev) => {
      if (sorting && sorting?.length > 0) {
        prev.set('sorting', `${sorting[0].id}:${sorting[0].desc ? 'desc' : 'asc'}`)
      } else {
        prev.delete('sorting')
      }
      return prev
    })
  }, [sorting])

  return {
    sorting,
    setSorting,
  }
}
