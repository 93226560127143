import React, { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { Select, SelectButton, SelectList } from '@saas-ui/react'
import { useTranslation } from 'react-i18next'
import { factoriesQuery } from '~/atoms/factoriesQuery'
import { factoryPicker } from '~/atoms/factoryPicker'
import { partnerPicker } from '~/atoms/partnerPicker'

const FactoryPicker = () => {
  const { t } = useTranslation(['translation'])
  const partner = useRecoilValue(partnerPicker)
  const factoriesList = useRecoilValue(factoriesQuery)
  const [factoryPickerState, setFactoriesPickerState] = useRecoilState(factoryPicker)

  useEffect(() => {
    setFactoriesPickerState(undefined)
  }, [partner])

  return factoriesList && factoriesList.length > 1 ? (
    <Select
      name="factory-picker"
      value={factoryPickerState ?? 'all'}
      onChange={(value) => {
        if (value === 'all') {
          setFactoriesPickerState(undefined)
          return
        }
        setFactoriesPickerState(value as string)
      }}
      options={[
        {
          value: 'all',
          label: t('all'),
        },
        ...factoriesList.map((factory) => ({
          value: factory.factory_name,
          label: factory.factory_name,
        })),
      ]}
    >
      <SelectButton />
      <SelectList zIndex="dropdown" />
    </Select>
  ) : null
}

export default () => (
  <React.Suspense>
    <FactoryPicker />
  </React.Suspense>
)
