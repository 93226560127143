import { Button, StackDivider, VStack, useToast } from '@chakra-ui/react'
import { Page, PageBody, PageHeader, Section, SectionBody, SectionHeader } from '@saas-ui-pro/react'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { newDemoWorkOrder, runScenario1 } from './api'

export default function DemoPage() {
  const { t } = useTranslation(['demo'])
  const toast = useToast()

  const mutationNewDemoWorkOrder = useMutation({
    mutationFn: newDemoWorkOrder,
    onSuccess: (result) => {
      toast({
        title: t('success', { ns: 'translation' }),
        description: result,
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    },
    onError: (error: any) => {
      toast({
        title: t('error', { ns: 'translation' }),
        description: error?.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    },
  })

  const mutationRunScenario1 = useMutation({
    mutationFn: runScenario1,
    onSuccess: (result) => {
      toast({
        title: t('success', { ns: 'translation' }),
        description: result,
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    },
    onError: (error: any) => {
      toast({
        title: t('error', { ns: 'translation' }),
        description: error?.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    },
  })

  return (
    <Page>
      <PageHeader title={t('title')} />
      <PageBody>
        <VStack divider={<StackDivider />} align="stretch" spacing={8} pb="16">
          <Section>
            <SectionHeader title={t('helpers')} />
            <SectionBody>
              <Button
                colorScheme="teal"
                isLoading={mutationNewDemoWorkOrder.isPending}
                onClick={() => mutationNewDemoWorkOrder.mutate()}
              >
                {t('new_demo_work_order')}
              </Button>
            </SectionBody>
          </Section>
          <Section>
            <SectionHeader title={t('scenarios')} />
            <SectionBody>
              <Button
                colorScheme="teal"
                isLoading={mutationRunScenario1.isPending}
                onClick={() => mutationRunScenario1.mutate()}
              >
                {t('run_scenario_n', { number: 1 })}
              </Button>
            </SectionBody>
          </Section>
        </VStack>
      </PageBody>
    </Page>
  )
}
