import { Button, Link, Stack, Text, ToastId, Tooltip, useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useShowToast } from '~/hooks/useShowToast'

export const useSaveToast = <TData,>(withResponseToast: boolean = true) => {
  const toast = useToast()
  const showToast = useShowToast()
  const saveChangesToastId: ToastId = 'toast-save-changes' as ToastId
  const { t } = useTranslation(['translation'])

  useEffect(() => {
    return () => {
      toast.close(saveChangesToastId)
    }
  }, [])

  const ToastSaveComponent = ({
    data,
    onSave,
    isDisabled,
    disabledMessage,
    onCancel,
  }: {
    data?: TData
    onSave: (data?: TData) => any
    isDisabled?: boolean
    disabledMessage?: string
    onCancel?: () => void
  }) => (
    <Stack
      direction="row"
      color="white"
      p={3}
      bg="green.500"
      justifyContent="space-between"
      alignItems="center"
      borderRadius={5}
      spacing={2}
      className="animate__animated animate__pulse animate__infinite	infinite animate__slow"
    >
      <Text>{t('when_you_ready')}</Text>
      <Tooltip label={disabledMessage}>
        <Button
          isDisabled={isDisabled}
          color="green.500"
          onClick={async () => {
            toast.close(saveChangesToastId)
            showToast(t('dont_close_the_window_saving_changes'), 'warning')
            const res = await onSave(data)
            if (withResponseToast) {
              if (res) {
                showToast(t('changes_saved_successfully'), 'success')
              } else {
                showToast(t('changes_saved_error'), 'error')
              }
            }
          }}
        >
          {t('save_changes')}
        </Button>
      </Tooltip>
      {onCancel && (
        <Link
          color="white"
          style={{
            textDecoration: 'underline',
          }}
          onClick={() => {
            toast.close(saveChangesToastId)
            onCancel()
          }}
        >
          {t('cancel')}
        </Link>
      )}
    </Stack>
  )

  const showSaveChangesToast = ({
    newData,
    onSave,
    isDisabled,
    disabledMessage,
    onCancel,
  }: {
    newData?: TData
    onSave: (data?: TData) => any
    isDisabled?: boolean
    disabledMessage?: string
    onCancel?: () => void
  }) => {
    if (toast.isActive(saveChangesToastId)) {
      toast.update(saveChangesToastId, {
        isClosable: false,
        duration: null,
        render: () => (
          <ToastSaveComponent
            data={newData}
            onSave={onSave}
            isDisabled={isDisabled}
            disabledMessage={disabledMessage}
            onCancel={onCancel}
          />
        ),
      })
    } else {
      toast({
        isClosable: false,
        duration: null,
        id: saveChangesToastId,
        render: () => (
          <ToastSaveComponent
            data={newData}
            onSave={onSave}
            isDisabled={isDisabled}
            onCancel={onCancel}
          />
        ),
      })
    }
  }

  return { showSaveChangesToast }
}
