import { navItemTheme } from './NavItem'
import { pageTheme } from './Page'

export const dataGridStyles = {
  baseStyle: {
    th: {
      color: 'var(--chakra-colors-primary)',
      backgroundColor: 'var(--chakra-colors-gray-100)',
      fontWeight: 800,
    },
  },
}

const components = {
  SuiPage: pageTheme,
  SuiNavItem: navItemTheme,
  SuiDataGrid: dataGridStyles,
}

export default components
