import { Icon } from '@chakra-ui/icons'
import { NavGroup, NavItem, SidebarSection } from '@saas-ui/react'
import { Link, useLocation } from 'react-router-dom'
import { LinkItemProps } from './SidebarLayout.types'

export default function SidebarBody({
  links,
  isOpen,
}: {
  links: Array<LinkItemProps>
  isOpen: boolean
}) {
  const location = useLocation()
  return (
    <SidebarSection flex="1" overflowY="auto">
      {links
        .filter((link) => !link.hide)
        .map((link) => {
          // Is a submenu
          if (link.children) {
            return (
              <NavGroup title={link.name} key={link.name} isCollapsible={isOpen}>
                {link.children
                  .filter((child) => !child.hide)
                  .map((child) => (
                    <NavItem
                      as={Link}
                      to={child.to!}
                      key={child.to}
                      icon={child.icon ? <Icon as={child.icon} /> : undefined}
                      isActive={location.pathname.startsWith(`/${child.to}`)}
                    >
                      {child.name}
                    </NavItem>
                  ))}
              </NavGroup>
            )
          }

          return (
            <NavItem
              as={Link}
              icon={link.icon ? <Icon as={link.icon} /> : undefined}
              isActive={location.pathname.startsWith(`/${link.to}`)}
              to={link.to!}
              key={link.to}
            >
              {link.name}
            </NavItem>
          )
        })}
    </SidebarSection>
  )
}
