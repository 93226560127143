import { DragEndEvent } from '@dnd-kit/core'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import parse from 'postgres-interval'
import { ChangedPropertiesObject } from '~/routes/production_planning_pages/operations/OperationDetailsPage/types'
import { moveArrayItem } from '~/utils/arraysUtils'

dayjs.extend(duration)

export const isTimeInMinutes = (time: any) => {
  // If time is number, it is in seconds
  if (typeof time === 'number') {
    return true
  }
  // If time is string and it is a number, it is in seconds
  if (typeof time === 'string' && !isNaN(Number(time))) {
    return true
  }

  return false
}

export const formatTimeInHoursAndMinutesFromMinutes = (minutes: number): string => {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  const formattedHours = hours.toString().padStart(1, '0')
  const formattedMinutes = remainingMinutes.toString().padStart(1, '0')

  return `${formattedHours}h ${formattedMinutes}mins`
}

export const formatTimeInHoursAndMinutes = (timeNeeded: string) => {
  if (!timeNeeded) return '0 h 0mins'

  if (isTimeInMinutes(timeNeeded)) {
    return formatTimeInHoursAndMinutesFromMinutes(parseInt(timeNeeded))
  }
  const timeArray = timeNeeded.split(':')
  if (timeArray.length !== 3) return ''
  // Split the time string into an array of hours, minutes, and seconds
  const hours = parseInt(timeArray[0]) // Convert the hours value to an integer
  const minutes = parseInt(timeArray[1]) // Convert the minutes value to an integer
  return `${hours} h ${minutes} mins`
}

export const formatTimeInMinutes = (timeNeeded: string) => {
  if (!timeNeeded) return '0 mins'

  // If time is number, it is in seconds
  if (isTimeInMinutes(timeNeeded)) {
    return `${parseInt(timeNeeded)} mins`
  }

  // Else it is in format hh:mm:ss
  const timeInMinutes = getMinutesFromTime(timeNeeded)
  return `${timeInMinutes} mins`
}

export const getMinutesFromTime = (timeNeeded: any): number => {
  if (isTimeInMinutes(timeNeeded)) return parseInt(timeNeeded)
  if (timeNeeded === '0' || timeNeeded === '') return 0
  const minutes = dayjs.duration(parse(timeNeeded)).as('minutes')
  return minutes
}

export const getChangedOperationProperties = <T extends Record<string, any>>(
  oldValue: T | null | undefined,
  newValue: T | null | undefined,
): ChangedPropertiesObject[] => {
  if (!oldValue || !newValue) return []
  const changesArray: ChangedPropertiesObject[] = []

  for (const key in oldValue) {
    if (
      Object.prototype.hasOwnProperty.call(oldValue, key) &&
      Object.prototype.hasOwnProperty.call(newValue, key)
    ) {
      if (oldValue[key] !== newValue[key]) {
        changesArray.push({
          propertyName: key,
          oldValue: oldValue[key],
          newValue: newValue[key],
        })
      }
    }
  }

  return changesArray
}

export function reorderChecks<T>(event: DragEndEvent, data: T[]): T[] {
  const initialIndex = event?.active?.id as number
  const endIndex = event?.over?.id as number
  return moveArrayItem(data, initialIndex, endIndex)
}
