import { supabase } from '~/utils/supabaseClient'

// Helpers
export const newSandboxPartners = async () => {
  // Setup partners Bonded Warehouse, Huckletree, Spaces
  await setUpPartner('Sandbox Area', [
    {
      factory_name: 'Bonded Warehouse',
      factory_location: { lat: 53.477026, lng: -2.2588223 },
    },
    {
      factory_name: 'Huckletree',
      factory_location: { lat: 53.4845265, lng: -2.2345289 },
    },
    {
      factory_name: 'Spaces',
      factory_location: { lat: 53.4783766, lng: -2.2491523 },
    },
  ])
}

async function setUpPartner(
  partner_name: string,
  factories: {
    factory_name: string
    factory_location: { lat: number; lng: number }
  }[],
) {
  // Create partners
  const { error: partnerError } = await supabase.functions.invoke('watchtower-create-partner', {
    body: {
      partner_name,
      partner_key: partner_name.toUpperCase().replace(' ', '').slice(0, 4),
      partner_domain: 'https://trunk.works/',
    },
  })
  if (partnerError) {
    throw new Error(partnerError.message)
  }
  // Create Factories
  const responseFactoryCreate = await Promise.all(
    factories.map((factory) =>
      supabase.functions.invoke('watchtower-create-factory', {
        body: {
          partner_name,
          factory_name: factory.factory_name,
          factory_location: factory.factory_location,
        },
      }),
    ),
  )
  if (responseFactoryCreate.some((response) => response.error)) {
    throw new Error(responseFactoryCreate!.find((response) => response.error)!.error!.message)
  }
  // Create zones Superstructure, Finishing Factory, Sub-assembly area for factory
  const responseZoneCreate = await Promise.all(
    factories
      .map((factory) => [
        supabase.functions.invoke('watchtower-create-zone', {
          body: {
            partner_name,
            factory_name: factory.factory_name,
            zone_name: 'Superstructure',
          },
        }),
        supabase.functions.invoke('watchtower-create-zone', {
          body: {
            partner_name,
            factory_name: factory.factory_name,
            zone_name: 'Finishing Factory',
          },
        }),
        supabase.functions.invoke('watchtower-create-zone', {
          body: {
            partner_name,
            factory_name: factory.factory_name,
            zone_name: 'Sub-assembly area',
          },
        }),
      ])
      .flat(),
  )

  if (responseZoneCreate.some((response) => response.error)) {
    throw new Error(responseZoneCreate!.find((response) => response.error)!.error!.message)
  }

  // Create stations for zones
  const responseStationCreate = await Promise.all(
    factories.map((factory) => createStations(partner_name, factory.factory_name)).flat(),
  )

  if (responseStationCreate.some((response) => response.error)) {
    throw new Error(responseStationCreate!.find((response) => response.error)!.error!.message)
  }
}

function createStations(partner_name: string, factory_name: string) {
  return [
    supabase.functions.invoke('watchtower-create-station', {
      body: {
        partner_name,
        factory_name,
        zone_name: 'Superstructure',
        station_name: 'Station 1',
      },
    }),
    supabase.functions.invoke('watchtower-create-station', {
      body: {
        partner_name,
        factory_name,
        zone_name: 'Superstructure',
        station_name: 'Station 2',
      },
    }),
    supabase.functions.invoke('watchtower-create-station', {
      body: {
        partner_name,
        factory_name,
        zone_name: 'Superstructure',
        station_name: 'Station 3',
      },
    }),
    supabase.functions.invoke('watchtower-create-station', {
      body: {
        partner_name,
        factory_name,
        zone_name: 'Superstructure',
        station_name: 'Station 4',
      },
    }),
    supabase.functions.invoke('watchtower-create-station', {
      body: {
        partner_name,
        factory_name,
        zone_name: 'Superstructure',
        station_name: 'Station 5',
      },
    }),
    supabase.functions.invoke('watchtower-create-station', {
      body: {
        partner_name,
        factory_name,
        zone_name: 'Finishing Factory',
        station_name: 'Station 1',
      },
    }),
    supabase.functions.invoke('watchtower-create-station', {
      body: {
        partner_name,
        factory_name,
        zone_name: 'Finishing Factory',
        station_name: 'Station 2',
      },
    }),
    supabase.functions.invoke('watchtower-create-station', {
      body: {
        partner_name,
        factory_name,
        zone_name: 'Finishing Factory',
        station_name: 'Station 3',
      },
    }),
    supabase.functions.invoke('watchtower-create-station', {
      body: {
        partner_name,
        factory_name,
        zone_name: 'Finishing Factory',
        station_name: 'Station 4',
      },
    }),
    supabase.functions.invoke('watchtower-create-station', {
      body: {
        partner_name,
        factory_name,
        zone_name: 'Finishing Factory',
        station_name: 'Station 5',
      },
    }),
    supabase.functions.invoke('watchtower-create-station', {
      body: {
        partner_name,
        factory_name,
        zone_name: 'Sub-assembly area',
        station_name: 'Station 1',
      },
    }),
    supabase.functions.invoke('watchtower-create-station', {
      body: {
        partner_name,
        factory_name,
        zone_name: 'Sub-assembly area',
        station_name: 'Station 2',
      },
    }),
    supabase.functions.invoke('watchtower-create-station', {
      body: {
        partner_name,
        factory_name,
        zone_name: 'Sub-assembly area',
        station_name: 'Station 3',
      },
    }),
    supabase.functions.invoke('watchtower-create-station', {
      body: {
        partner_name,
        factory_name,
        zone_name: 'Sub-assembly area',
        station_name: 'Station 4',
      },
    }),
    supabase.functions.invoke('watchtower-create-station', {
      body: {
        partner_name,
        factory_name,
        zone_name: 'Sub-assembly area',
        station_name: 'Station 5',
      },
    }),
  ]
}
