import { useDisclosure } from '@chakra-ui/react'
import { Sidebar, SidebarToggleButton } from '@saas-ui/react'
import SidebarBody from './SidebarBody'
import { SidebarFooter } from './SidebarFooter'
import { SidebarHeader } from './SidebarHeader'
import { LinkItemProps } from './SidebarLayout.types'

export type { LinkItemProps }

export default function SidebarLayout({
  links,
  logout,
}: {
  links: Array<LinkItemProps>
  logout: () => void
}) {
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: true,
  })

  return (
    <Sidebar
      variant={isOpen ? 'default' : 'compact'}
      transition="width"
      transitionDuration="normal"
      width={isOpen ? '250px' : '14'}
      minWidth="auto"
    >
      <SidebarToggleButton />
      <SidebarHeader isOpen={isOpen} onToggle={onToggle} />
      <SidebarBody links={links} isOpen={isOpen} />
      <SidebarFooter isOpen={isOpen} logout={logout} />
    </Sidebar>
  )
}
