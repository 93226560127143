import { Flex, Text } from '@chakra-ui/react'

export type EnvType = 'prod' | 'dev' | 'staging' | 'demo'

export default function PageBanner({ env }: { env: EnvType }) {
  if (env === 'prod') {
    return null
  }

  return (
    <Flex
      backgroundColor={env === 'dev' ? 'green.300' : env === 'staging' ? 'purple.300' : 'blue.300'}
      justifyContent="center"
    >
      <Text color="white" fontWeight="bold" my={1}>
        {env === 'dev' ? 'Development' : env === 'staging' ? 'Staging' : 'Demo'}
      </Text>
    </Flex>
  )
}
